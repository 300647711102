.header {
	background-color: var(--bg1);
	color: var(--fg1);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.logoContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 30px;
	gap: 12px;
}

.logo1 {
	font-size: 24px;
	color: white;
	font-weight: bolder;
}

.logo2 {
	font-size: 24px;
	color: white;
	font-weight: lighter;
}

.logo3 {
	font-size: 15px;
	color: white;
	font-weight: lighter;
}

.profileContainer {
	padding: 30px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 12px;
}

.image {
	width: 40px;
	height: 40px;
	border-radius: 40px;
}
