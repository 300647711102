.page {

}

.navButtonStack {

}

.padding {
    padding-left: var(--gap);
}