
.page {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    background-color: var(--bg1);
    color: var(--fg1);

    margin-bottom: var(--gap);
    margin-left: var(--gap);
    margin-right: var(--gap);
    margin-top: var(--gap);
    
    animation-duration: 1s;
    animation-name: my-animation;
    
    
}

@keyframes my-animation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.pageHeader {
    padding: var(--gap);
    display: flex;
    flex-direction: column;
}

.listitem {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--bg3);
    color: var(--fg3);
    padding: 15px;
    border-radius: var(--borderRadiusLarge);
}

.text {
    text-wrap: normal;
}

.result {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    white-space: nowrap;
    align-items: flex-start;
    flex-grow: 6;
    padding-left: 1rem;
}

.tileLevelOne {
    background-color: var(--bg2);
    color: var(--fg2);
    padding: 30px;
    border-radius: var(--borderRadiusLarge);
}

.tileLevelTwo {
    background-color: var(--bg3);
    color: var(--fg3);
    padding: 30px;
    border-radius: var(--borderRadiusLarge);
}

.tileLevelThree {
    background-color: var(--bg4);
    color: var(--fg3);
    padding: 30px;
    border-radius: var(--borderRadiusLarge);
}

.tileDanger {
    background-color: var(--bgDanger);
    color: var(--fgDanger);
    padding: 30px;
    border-radius: var(--borderRadiusLarge);
}

@media print {
    
    .noPrint {
        display: none;
    }

    .page {
        gap:0;
        margin:0;
    }
    
    
}

.header {
    padding: 30px;
    border-radius: var(--borderRadiusLarge);
    background-color: var(--bg1);
    color: white;

}
