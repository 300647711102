
.flightPlanHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top:1rem;
    margin-bottom:1rem;
}

@media print {
    .flightPlanHeader {
        border: 1px solid black;
        margin: 0px !important;
        padding: 10px !important;
        border-radius: 0 !important;
        margin-bottom: 10px !important;
    }
    textArea {
        display: none;
    }
    .flightPlanTable {
        border-radius: 0 !important;
    }
    .tableContainer {
        border-radius: 0 !important;
    }
    .flightPlanFooter {
        margin: 0px !important;
        padding: 10px !important;
        border-radius: 0 !important;
        border: 1px solid black;
        
    }
}

.text {
    border: black 2px;
}

.flightPlanFooter {
    display: flex;
    flex-direction: column;
    color: black;
}

.tableContainer {
    border: 1px black solid;
    border-radius: var(--borderRadiusLarge);
    margin-bottom: 1rem;
    box-shadow:  inset 0 0 0 1px black;
}

.flightPlanTable {
    width: 100%;
    overflow: hidden;
    list-style: none;
    text-align: left;
    cursor: pointer;
    transition: all 0.7s;
    border-collapse: collapse;
    border-radius: var(--borderRadiusLarge);
    background-color: white !important;
    color:black;
}


textarea {
    border:0px;
    width: 100%;
    height: 100%;
}

td, th {
    border-left:solid black 1px;
    border-top:solid black 1px;
    padding: 0.3rem;
}


th {
    background-color: #bfd2dd;
    border-top: none;
    text-align: center;
}

td:first-child, th:first-child {
    border-left: none;
}