.page {
	background-image: url("/public/images/dan-lohmar-0zeb4q6odlE-unsplash.jpg");
	background-size: cover;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	box-sizing: border-box;
	height: 80%;
}

.fader {
	flex-grow: 1;
	background-image: linear-gradient(to left, rgba(72, 111, 136, 0.8), rgba(72, 111, 136, 1));
	display: flex;
	flex-direction: row;
	backdrop-filter: blur(5px);
}

.left {
	display: flex;
	flex-direction: column;
	color: var(--fg1);
	align-content: center;
	height: 100%;
	flex-wrap: wrap;
	justify-content: center;
	text-wrap: normal;
	width: 60%;
	padding: calc(2 * var(--gap));
	gap: var(--gap);
}

.right {
	color: var(--fg1);
	height: 100%;
	width: 40%;
}

.startButtonWrapper {
	flex-direction: row;
	display: flex;
	gap: var(--gap);
	align-items: center;
}

.startButton {
	background-color: green;
	border-radius: var(--borderRadiusLarge);
	padding: var(--gap);
	flex-grow: 0;
	border: 1px solid darkgreen;
}

.loginButton {
	height: 40px;
	margin: 0px;
	border-radius: 5px;
}