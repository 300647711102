.phoneRow {
    display: flex;
    flex-direction: row;
    flex-wrap: none;
    gap: var(--gap);
    align-items: baseline;
}

.phonePrefix {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: calc(0.8vmin + 0.8rem);
    height: fit-content;
}

.phoneInput {
    min-width: 60%;
    flex-grow: 1;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: calc(0.8vmin + 0.8rem);
    height: fit-content;
}

.loginButton {
    background-color: var(--bgCleared);
    color: var(--fg1);
    border: 1px solid var(--fg1);
    height: 40px;
    margin: 0px;
    border-radius: 5px;
    width: 100%;
}

.smsLogin {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    justify-content: center;
    height: 100%;
    padding: calc(2 * var(--gap));
}

.otpInput {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: calc(0.8vmin + 0.8rem);
    height: fit-content;
    width: 20px;
}