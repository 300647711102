.wavListItem {
    display: flex;
    flex-direction: row;
    align-items: center;   
    min-height: 19px;
}

.soundByte {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    align-self: center;
    width: 5px;
    background-color: var(--fg1);
}

.soundByteHeight {
    color: var(--bg3);
    background-color: var(--bg2);
    height: 19px;
}

