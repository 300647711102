
.scoreContainer {
    width: 100px;
    height: 100px;
    text-align: center;
    display: flex;
    flex-direction: row;
}

.scoreSvg {
    width: 100px;
    height: 100px;
    position: absolute;
}

.scoreText {
    z-index: 999;
    width: 100px;
    align-self: center;
    text-align: center;
    position: absolute;
}
