:root {
    --bg1: #486f88;
    --bg2: #5b8aa8;
    --bg3: #9dbacc;
    --bg4: #bfd2dd;
    --bg5: #e0e9ef;
    --fg1: #ffffff;
    --fg2: #ffffff;
    --fg3: #000000;
    --gap: 25px;
    --bgDanger: maroon;
    --bgCleared: green;
    --fgDanger: white;
    --borderRadiusLarge: 16px;
    --borderRadiusSmall: 12px;
    --bordercColor: black;
    --borderWidth: 1px;
}

.App {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: calc(0.8vmin + 0.8rem);
    min-height: 100vh;
    background-color: var(--bg1);
}

@media print {
    .App {
        min-height: 0;
        font-size: 12px;
        align-content: flex-start;
        margin: 0;
        padding: 0;
    }

    .noPrint {
        display: none !important;
    }
}

body {}

html {}

.logo1large {
    font-size: calc(2vmin + 1rem);
    color: white;
    font-weight: bolder;
}

.logo2large {
    font-size: calc(2vmin + 1rem);
    color: white;
    font-weight: lighter;
}



a:link {
    text-decoration: none;
    color: inherit;
}

a:visited {
    text-decoration: none;
    color: inherit;
}

a:hover {
    text-decoration: none;
    color: inherit;
}

a:active {
    text-decoration: none;
    color: inherit;
}

h1 {
    font-size: calc(2vmin + 1rem);
    font-family: Inter, serif;
    font-weight: 400;
    line-height: normal;
    display: inline-block;
    margin-block-end: 0;
}

h2 {
    font-family: Inter, serif;
    font-size: calc(1.5vmin + 1rem);
    font-weight: 400;
    line-height: normal;
    display: inline-block;
    margin-block-end: 0;
}

h3 {
    font-size: calc(1vmin + 1rem);
    font-family: Inter, serif;
    font-weight: 400;
    line-height: normal;
    display: inline-block;
    margin-block-end: 0px;
    margin-block-start: 0px;
}

h4 {
    font-size: calc(0.5vmin + 1rem);
    font-family: Inter, serif;
    font-weight: 400;
    line-height: normal;
    display: inline-block;
    margin-block-end: 0px;
    margin-block-start: 0px;
    margin-left: 11px;
}

.appContent {
    min-height: calc(100vh - 200px);
}

.footer {
    margin-top: 0;
    /* take available whitespace */
    padding-bottom: 5px;
    padding-top: 5px;
    height: 80px;
    text-align: center;
    font-size: calc(0.5vmin + 0.5rem);
    font-family: Inter, serif;
    font-weight: 400;
    line-height: normal;
}