.iconButton.undo::after {
    content: "undo";
    cursor: pointer;
}

.iconButton.play_circle::after {
    content: "play_circle";
    cursor: pointer;
}

.iconButton.play_circle:hover {
    border: var(--bordercColor) var(--borderWidth) solid;
    background: darkseagreen;
    border-radius:var(--borderRadiusSmall);
    display:flex;
    cursor: pointer;
}


.iconButton.replay::after {
    content: "replay";
    cursor: pointer;
}

.iconButton.goto::after {
    content: "fast_rewind";
    cursor: pointer;
}

.iconButton.arrow_back::after {
    content: "arrow_back";
    cursor: pointer;
}

.iconButton.arrow_forward::after {
    content: "arrow_forward";
    cursor: pointer;
}

.iconButton::after {
    font-family: "Material Symbols Outlined", serif;
    padding-right: 1rem;
    padding-left: 1rem;
    font-size: calc(1.0vmin + 1.0rem);
}