
.enabled {
    border: 1px solid black;
    background: darkseagreen;
    padding: 1rem;
    border-radius: var(--borderRadiusLarge);
    display:flex;
    cursor: pointer;
}

.disabled {
    border: 1px solid black;
    background: grey;
    padding: 1rem;
    border-radius: var(--borderRadiusLarge);
    display:flex;
}

.text {
}