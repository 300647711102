
.matchItem {
    padding-right: 0.5rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    align-self: center;
}

.matchItem > * {
    align-self: center;
}
