.container {

}

.list {
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    display: flex;
    flex-direction: column-reverse;
    gap:30px;
    margin-bottom: 30px;
}

.fadeList {
    
}

.fadeList li {
    opacity:0.1 !important;
}
.fadeList li:nth-last-child(1) {
    opacity: 1 !important;
}
.fadeList li:nth-last-child(2) {
    opacity: 1 !important;
}
.fadeList li:nth-last-child(3) {
    opacity: 1 !important;
}
.fadeList li:nth-last-child(4) {
    opacity: 0.8 !important;
}
.fadeList li:nth-last-child(5) {
    opacity: 0.5 !important;
}
.fadeList li:nth-last-child(6) {
    opacity: 0.3 !important;
}
